
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        






















































































































































































.content {
    width: 290px;

    .field {
        ::v-deep {
            .label {
                margin-left: 0;
            }

            .field-body {
                .field {
                    row-gap: 0.5rem;
                    flex-direction: column;
                }
            }

            .b-radio {
                font-size: 0.85rem;

                .check.is-info {
                    border-color: transparentize($light, .5);
                }
            }
        }
    }
}
