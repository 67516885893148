
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        




































































































.field {
    $self: &;

    ::v-deep {
        .help {
            font-size: $size-small;

            &:not(.is-danger) {
            }
        }
    }

    &.is-left {
        ::v-deep {
            .help {
                text-align: left;
            }
        }
    }

    &.is-right {
        ::v-deep {
            .help {
                text-align: right;
            }
        }
    }

    ::v-deep {
        #{ $self } {
            .help {
                &.hidden {
                    display: none;
                }

                &:not(.hidden) {
                    margin-left: calc(-1 * var(--maxWidth));
                }
            }

            &.hidden {
                .help {
                    display: none;
                }
            }

            &.is-grouped {
                #{ $self } {
                    margin-right: 0;

                    &:first-of-type {
                        .vs__dropdown-toggle,
                        .button,
                        input {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                    }

                    &:nth-of-type(2) {
                        .vs__dropdown-toggle,
                        .button,
                        input {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                        }
                    }

                    &:not(.is-expanded) {
                        max-width: var(--maxWidth);
                    }

                    &.b-numberinput {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}
