
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        









































.logo {
  position: relative;
  height: $navbar-height;
  display: flex;
  align-items: center;
  padding-right: 16px;

  .is-main, &-image-square {
    width: 100%;
    object-fit: cover;
  }

  .is-main {
    //height: 1.45rem;
  }

  &-image-square {
    height: $navbar-height;
  }
}
