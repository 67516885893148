
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        




























































.title {
    line-height: inherit;
}

.image {
    //noinspection SassScssUnresolvedMixin
    @include touch {
        max-width: 60%;
        margin: auto !important;
    }

    &.is-1by1 {
        width: 175px;
    }

    &.is-3by1 {
        width: 124px;
    }
}
