
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        










































































































::v-deep {
    .b-radio.radio.button {
        justify-content: flex-start;
        height: 3.5rem;
        font-size: 0.95rem;
        color: #363636;
        border-color: transparent;
        border-radius: 6px;

        &.is-selected, &:hover {
            border: 1px solid #FFBA40;
        }
    }
}

.wrapper-content, form {
    height: 100%;
}

.wrapper-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.paymentDefaultHeight {
    height: 272px;
}

.list {
    min-height: 17rem;
    max-height: 17rem;
    background: #F5F8FA;
    border-right: 1px solid #ECECEC;
    overflow: auto;
}
