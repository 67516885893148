@import "theme-default";
@import "~bulma/sass/utilities/functions";

// Для нового года

//.has-navbar-fixed-top {
//    padding-top: $navbar-height + $garland-height !important;
//}
//
//.common-table {
//    thead {
//
//        tr {
//            th {
//                padding-top: $garland-height;
//            }
//        }
//    }
//}

// ----------------

html {
    overflow-x: auto;
}

.is-clickable {
    cursor: pointer;
}

.is-clickable-default {
  cursor: default
}

.mr-auto {
    margin-right: auto;
}

.ml-auto {
    margin-left: auto;
}

//TODO: пока не обновился buefy
.is-align-items-center {
    display: flex;
    align-items: center;
}

.card {
    &-content {
        padding: 1rem;
    }
}

//закомментировано в рамках задачи LIMONAD-3460
//.columns {
//    .column {
//        &:empty {
//            padding: 0;
//        }
//    }
//}

.timepicker {
    .dropdown {
        &-trigger {
            .control {
                &:after {
                    border-collapse: collapse;
                }
            }
        }
    }
}

.control {
    $iconSize: 35px;

    &.has-icons-left, &.has-icons-right {
        .icon {
            top: 0;
            bottom: 0;
            width: $iconSize;
            margin: auto;
        }
    }

    &.has-icons-left {
        .input {
            padding-left: $iconSize;
        }
    }

    &.has-icons-right {
        .input {
            padding-right: $iconSize;
        }
    }

    &.is-loading {
        &:after {
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }
}

.autocomplete {
    .dropdown-menu {
        width: 100%;
        min-width: 0 !important;
    }
}

.dropdown {
    $self: &;
    #{ $self }-menu {
        #{ $self }-trigger {}
    
        #{ $self }-content {
            &:empty {
                display: none;
            }
            
            #{ $self }-item {
                .manager-info-field {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

.field {
    & > .label {
        font-weight: 400;
        margin-left: 0.7em;
        margin-top: -0.3em;
        margin-bottom: 0.3em;
        
        &:not(:last-child) {
            margin-bottom: 0.3em;
        }
    }

    &.has-addons {
        .field {
            margin-bottom: 0;
        }
    }
}

.input, .button {
    font-family: inherit;
    border-radius: $radius;
    box-shadow: none !important;
}

.b-numberinput {
    $size-small: 0.875rem;

    .input.is-small {
        &[type="number"] {
            font-size: $size-small;
        }
    }

    .button.is-small {
        font-size: $size-small;

        .icon.is-small {
            font-size: $size-small;
        }
    }

    .control:nth-child(2) {
        width: 100%;
    }
}

.button {
    &.is-rounded {
        //noinspection SassScssUnresolvedVariable
        border-radius: $radius-rounded;
        padding-left: calc(1em + 0.167em);
        padding-right: calc(1em + 0.167em);
    }

    &.is-outlined {
        $types: (
            success: $success $success-light,
            info: $info $info-light,
            warning: $warning $warning-light,
            danger: $danger $danger-light
        );

        @each $type, $color-vars in $types {
            &.is-#{$type} {
                &:hover {
                    background-color: nth($color-vars, 2);
                    border-color: transparent;
                    color: nth($color-vars, 1);
                }
            }
        }
    }

    .icon {
        $size: 2em;
        display: flex;
        font-size: 1.2em;
        align-items: center;
        justify-content: center;
        width: $size !important;
        height: $size !important;

        &:first-child:last-child {
            //noinspection SassScssUnresolvedVariable
            margin-left: -14.5px;
            //noinspection SassScssUnresolvedVariable
            margin-right: -14.5px;
        }

        img {
            max-width: 100%;
        }
    }

    img {
        width: auto;
        height: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.select {
    &:not(.is-multiple) {
        height: auto;
    }

    &:after {
        top: 0;
        bottom: 0;
        margin: auto;
    }

    &.is-loading {
        &:after {
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }

    select {
        height: auto;
        box-shadow: none;
        font-family: inherit;
    }
}

.upload {
    & + .file-name {
        font-size: $size-normal;
        //padding: $button-padding-vertical $button-padding-horizontal;
    }

    .upload-draggable {
        &.is-loading {
            &:after {
                top: 0;
                bottom: 0;
                margin: auto;
                position: absolute;
            }
        }
    }
}

.checkbox {
    &:hover {
        input[type=checkbox] {
            &:not(:disabled) {
                &:not(:checked) {
                    & + .check {
                        border-color: transparent !important;
                    }
                }
            }
        }
    }

    input[type=checkbox] {
        &:focus {
            & + .check {
                box-shadow: none !important;
            }

            &:checked + .check {
                box-shadow: none !important;
            }
        }
    }

    .control-label {
        &:empty {
            padding: 0;
        }
    }
}

.switch {
    input[type=checkbox] {
        & + .check {
            background: #D6D6D6;
        }
    }

    input[type=checkbox] {
        &:focus {
            & + .check {
                box-shadow: none !important;
            }

            &:checked + .check {
                box-shadow: none !important;
            }
        }
    }
}

.notification {
    border: 1px solid;
    border-radius: 10px;

    &.is-info {
        color: $info;
        background-color: mix(white, $info, 93%);

        .delete {
            background-color: rgba(0, 55, 255, 0.2);
        }
    }

    &.is-danger {
        color: $danger;
        background-color: mix(white, $danger, 93%);

        .delete {
            background-color: rgba(255, 0, 0, 0.2);
        }
    }
}

.message {
    &.is-info {
        .message-body {
            color: $info;

        }

        .delete {
            background-color: rgba(0, 55, 255, 0.2);
        }
    }

    &.is-danger {
        .message-body {
            color: $danger;

        }

        .delete {
            background-color: rgba(255, 0, 0, 0.2);
        }
    }
}

.b-tabs {
    .tabs {
        margin-bottom: 0;
    }
}

.v-popover {
    vertical-align: top;
}

.table {
    &.vuetable {
        th {
            .sort-icon {
                left: 0 !important;
                bottom: 0 !important;
                float: right !important;
                transform: none !important;
            }
        }
    }
}

.taginput .taginput-container.is-focusable, .textarea, .input {
    width: 100%;
    max-width: 100%;
}

.confirm-mail {
    overflow: hidden;
    
    .container {
        max-width: 270px !important;
    }
}

.b-table {
    .table-wrapper {
        & + .level {
            margin-top: -1.5rem;
            justify-content: flex-end;
        }
    }
}

.is-content-box {
  box-sizing: content-box;
}
