.news {
    &-items {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: space-between;
    }

    &-item {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 16px;
        border-radius: 10px;
        background: #FFFFFF;
        box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05);
    }

    &-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        max-width: 70%;
        height: min-content;
    }

    &-img {
        margin: 0 20px 20px 0;
        width: 160px;
        height: 160px;
        max-width: 160px;
        max-height: 160px;
        background: #cccccc;
    }

    &-title {
        box-sizing: border-box;
        margin: 0 0 10px;
        width: 100%;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        color: #0B0B0B;
    }

    &-description {
        font-size: 18px;
        line-height: 21px;
        color: #0B0B0B
    }

    &-time {
        font-size: 14px;
        color: #A5A5A5;
    }

    &-date {
        position: relative;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        padding: 5px 50px 5px 5px;
        margin: 0 60px 0 0;
        font-size: 14px;
        color: #FFFFFF;
        background: #F86E7F;
        white-space: nowrap;

        &:after {
            position: absolute;
            content: '';
            top: 4px;
            right: -11px;
            background: #fff;
            width: 22px;
            height: 22px;
            transform: rotate(45deg);
        }
    }

}

.date {
    &--wrapper {
        display: flex;
        align-items: center;
        padding: 0 0 15px 0;
    }

    &-img {
        display: flex;
        max-width: 20px;
        margin: 0 10px 0 0;
        align-self: flex-start;
    }
}
