
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        




























































































































































































































































::v-deep {
  .modal-card, .modal-card-body {
    overflow: visible;
  }

  .modal-card-foot {
    border-radius: 0 0 10px 10px;
  }

  .modal-card-head {
    border-radius: 10px 10px 0 0;
  }

  .modal-card {
    max-width: 700px;
    width: 100vw;
  }
}
