
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        












































































































































































































































































































































































































.card-profile_body-tooltip {
    padding: .4rem;

    &_wrapper {
        max-height: 150px;
        overflow: auto;
    }

    &_title {
        margin: .5rem 0;

        font-weight: 600;
        font-size: 1.063rem;
    }

    &_img {
        $size: 1.563rem;

        width: $size;
        height: $size;

        margin-right: .313rem;
        border: 1px solid #E1E1E1;
        border-radius: 50%;
    }

    &_content {
        display: flex;
        align-items: center;

        padding: .625rem .5rem .625rem 0;

        &:not(:last-child) {
            border-bottom: 1px solid #E9E9E9;
        }
    }
}
