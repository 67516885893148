
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        







































































$colors: (
        "white": ($white),
        "light": ($light),
        "primary": ($primary),
        "info": ($info),
        "success": ($success),
        "warning": ($warning),
        "danger": ($danger),
);

$x-placements: (
    top: (left, right),
    bottom: (left, right),
    right: (top, bottom),
    left: (top, bottom)
);

.vs__selected .before .tippy {
    display: inline-block;
}

.tippy {
    display: inline-block;

    &-tooltip {
        display: flex;
        align-items: center;
        min-height: 33px;
    }
}

.column > .tippy,
.field .tippy {
    display: block;
}

.tippy-content {
    white-space: pre-line;
    text-align: left;
    overflow: hidden;
    word-wrap: break-word;

    .tooltip-content-list {
        width: 100%;
        padding: 0 !important;
        margin: 0 !important;
    }
}

@each $name, $glyph in $colors {
    .tippy-tooltip.#{$name}-theme {
        /* Your styling here. Example: */
        background-color: $glyph;
        border: 2px solid $glyph;
        filter: drop-shadow(0 2px 3px rgba(0, 1, 0, 0.2));

        @if($name == "white") {
            color: black;
         }
    }

    @each $x-placement, $sides in $x-placements {
        .tippy-popper[x-placement^=#{$x-placement}] .tippy-tooltip.#{$name}-theme .tippy-arrow {
            border-#{$x-placement}-color: $glyph;

            @if($name == "white") {
                &:after {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    border-#{$x-placement}: 3px solid #f5f5f5;
                    transform-origin: 0 50%;

                    @each $side in $sides {
                        border-#{$side}: 3px solid transparent;
                    }
                }
            }
        }

        .tippy-popper {
            &[x-placement^=right] .tippy-arrow:after {
                margin: -5px -2px;
            }

            &[x-placement^=left] .tippy-arrow:after {
                margin: -5px -5px;
            }

            &[x-placement^=top] .tippy-arrow:after {
                margin: -5px -5px;
            }

            &[x-placement^=bottom] .tippy-arrow:after {
                margin: -2px -5px;
            }
        }
    }
}
