// TODO отрефакторить стили финансов
.ticket-modal--head {
    font-size: 20px;
    color: #131313;
}

.ticket-modal--body {
    text-align: center;
}

.table-item--link {
    color: #212121;
}

.table-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


//Common table styles for buefy table
$table-border: #EDEDED;
$table-white-background: rgba(255, 255, 255, 1);
$table-transparent-background: rgba(255, 255, 255, 0);
$table-custom-background: #F5F8FA;
$table-font-size: 14px;
$table-font-color: #212121;
$table-border-radius: 10px;


.common-table {
    .table-wrapper {
        margin-bottom: .8rem;
    }
    thead {
        color: $table-font-color;
        font-size: $table-font-size;

        tr {
            th {
                top: $navbar-height;
                z-index: 2;
                position: sticky;
                background: $table-custom-background;
            }
        }
    }

    tbody {
        tr {
            &:first-of-type {
                td {
                    &:first-child {
                        border-top-left-radius: $table-border-radius;
                    }

                    &:nth-last-child(1) {
                        border-top-right-radius: $table-border-radius;
                    }
                }
            }

            &:last-of-type {
                td {
                    &:first-child {
                        border-bottom-left-radius: $table-border-radius;
                    }

                    &:nth-last-child(1) {
                        border-bottom-right-radius: $table-border-radius;
                    }
                }
            }

            td {
                position: relative;
                border-bottom: 1px solid $table-border;
                vertical-align: middle;
                //background: $table-white-background;

                &.has-text-centered {
                    img {
                        margin-bottom: -4px;
                    }
                }

                //&:after {
                //    position: absolute;
                //    content: "";
                //    top: 50%;
                //    right: 0;
                //    display: block;
                //    width: 1px;
                //    height: 75%;
                //    background-color: $table-border;
                //    -webkit-transform: translateY(-50%);
                //    transform: translateY(-50%);
                //}
            }
        }
    }

    /*  *Перебивание некоторых стилей common-table для создания представления таблиц второго типа - офферы, тикеты (с кнопками, не требующими бэкграунда) на основе таблиц первого типа - common-table*/


    &.with-options {
        tbody {
            tr {
                &:first-of-type {
                    td {
                        &:nth-last-child(2) {
                            border-top-right-radius: $table-border-radius;
                        }

                        &:nth-last-child(1) {
                            border-bottom-right-radius: unset !important;
                            background: $table-custom-background;
                            border-bottom: none;
                        }
                    }
                }

                &:last-of-type {
                    td {
                        &:nth-last-child(2) {
                            border-bottom-right-radius: $table-border-radius;
                        }
                    }
                }

                td {
                    &:nth-last-child(1) {
                        $offset: 0.8em;
                        width: 1%;
                        padding: $offset;
                        border-bottom: none;
                        white-space: nowrap;
                        background: $table-custom-background;
                        border-bottom-right-radius: initial !important;

                        & > *:not(:last-child) {
                            margin-right: $offset;
                        }
                    }
                }
            }
        }
    }
}

//styles for country flag (use w plugin and common table)
.flag {
    &-size {
        line-height: 1em;

        span {
            border-radius: 50%;
        }
    }

    &-icon {
        $size: 22px;

        border-radius: 50%;
        width: $size !important;
        height: $size !important;
        border: 1px solid #ececec;
    }
}

// Стили для таблиц 3-го типа - Детализация / Статистика

.filter-tables--container {
    position: relative;
}

.statistic-table--column {
    position: relative;
    vertical-align: middle !important;
}

//.statistic-table--column:after {
//    position: absolute;
//    content: "";
//    display: block;
//    right: 0;
//    top: 50%;
//    width: 1px;
//    height: 75%;
//    background-color: #EDEDED;
//    transform: translateY(-50%);
//}

.statistic-table table tbody tr td {
    border-top: 1px solid #EDEDED !important;
}

.statistic-table--list {
    list-style-type: none;
}

.filter-tables--wrapper {
    display: flex;
    width: 100%;
    border-radius: 20px;
}

.filter-table--wrapper {
    width: 100%;
}

.filter-table--head {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    max-width: 50%;;
    height: 53px;
    font-size: 14px;
    border-radius: 10px 0 0 0;
    background: #7ADDE2;
    color: #FFFFFF;
    text-transform: uppercase;
}

.custom {
    background: #91E3D7;
    border-radius: 0 10px 0 0;
}

.statistic-table {
    table {
        tbody {
            tr {
                &:last-child {
                    td {
                        border-bottom-left-radius: $table-border-radius;
                        border-bottom-right-radius: $table-border-radius;
                    }
                }
            }
        }
    }
}

.statistic-table table thead th {
    padding: 1rem;
    font-size: 14px;
    color: #CDCDCD !important;
    background: #fff;
    white-space: nowrap;
    vertical-align: baseline !important;
    text-align: center !important;
}

.statistic-table--column:last-child::after {
    display: none;
}

//styles for truncate row for statistic table
.truncate {
    white-space: nowrap;
    text-overflow: clip;
    overflow: hidden;

    &.ellipsis {
      text-overflow: ellipsis;
    }
}
