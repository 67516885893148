
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        




























































































































































$color-pagination-button: #b5b5b5;
$color-border: #dbdbdb;

.sticky-wrapper {
    position: sticky;
    right: 2rem;
    display: flex;
    align-items: center;
    row-gap: 1rem;

    ::v-deep {
        .info {
           display: none;
        }
        .pagination-link {
            color: $color-pagination-button;
            background-color: white;
            max-width: 39px;
            border-color: $color-border;

            &.is-current {
                border-color: $primary;
            }
        }
    }
}
