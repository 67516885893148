
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        



























































.card {
    margin-bottom: 0 !important;
}

.snackbar {
    z-index: 40;
    position: fixed;
    margin: 0;
    bottom: .5rem;
    left: 50%;
    transform: translate(-50%, -10%);
    box-shadow: 0 0 10px rgba(172, 172, 172, 0.7);
    &-content {
        display: flex;
        @media screen and (max-width: 1440px) {
            flex-wrap: wrap;
        }
    }
}
