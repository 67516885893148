
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        




































































































.tooltip-content-list {
    margin: -0.35rem -0.75rem;
    padding: 0.35rem 0.75rem;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 2;
}
