
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        






















































































































































































































.modal {
    &-header {
        display: block;
        margin-bottom: 10px;
        font-size: 20px;
        color: #131313;
    }

    &-sum {
        margin-right: 10px;
        width: 130px;
        min-width: 130px;

        input {
            height: 44px;
        }
    }

    &-holder {
        display: inline-flex;
        justify-content: space-between;
        width: 47%;
    }

    &-wrapper {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
    }

    &-currency {
        max-width: 90px;
        width: 90px;
    }

    &-payment {
        &-system {
            padding-top: 27px;
            width: 100%;
        }
    }

    .currency {
        display: block;
        min-height: 21px;
        margin-bottom: 7px;
        font-size: 14px;
        align-items: center;
        color: #212121;

        &-select {
            height: 45px;
        }
    }
}
