
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        






































































































































































































.is-active {
    ::v-deep {
        .mx-input-wrapper {
            .mx-icon-clear {
                display: block;
            }
        }
    }
}

.empty {
    color: $placeholder-color;
}

.mx-datepicker {
    width: 100%;
}

.input-wrapper {
    display: flex;
    width: 100%;
    background: #FFFFFF;
    padding: 6px 8px;
    align-items: center;
    justify-content: start;
    flex-wrap: nowrap;
    cursor: pointer;
    border: 1px solid #dbdbdb;
    border-radius: 0.25rem;

    &:hover {
        border-color: $light;
    }

    &.disabled {
        cursor: not-allowed;
        color: $light;
        background-color: $light-light;
        border-color: transparent;
    }
}

.header-wrapper {
    display: flex;
    justify-content: space-evenly;

    &:hover {
        color: $primary;
    }

    .is-active {
        color: $primary;
    }
}
