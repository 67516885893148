.payment {
    &-section {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;
        background-color: #fefefe;
    }
}

