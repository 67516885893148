
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        
































































































































@import "src/scss/variables";

$colors: (
        "info": ($info),
        "success": ($success),
        "warning": ($warning),
        "gray": ($light-gray),
        "danger": ($danger),
        "dark": ($dark),
        "light": ($light)
);

span {
    $size: 10px;

    position: relative;
    white-space: nowrap;
    
    .tippy {
        vertical-align: middle;
    }
    
    .before, .after {
        .tippy {
            vertical-align: initial;
        }
    }
    
    .before {
        .tippy {
            padding-right: 0.4em;
        }
    }
    
    .after {
        .tippy {
            padding-left: 0.4em
        }
    }

    i {
        width: $size;
        height: $size;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        background-color: transparent;

        @each $name, $glyph in $colors {
            &.is-#{$name} {
                background-color: $glyph;

                &.is-light {
                    background-color: transparentize($glyph, .5);
                }
            }
        }
    }
}
