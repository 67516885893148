html {
  scrollbar-width: none;
}

* {
  //scrollbar-color: #bfd2ff transparent;
  //scrollbar-width: thin;
}

/* Только внешний скролл */
body::-webkit-scrollbar {
  display: none;
}

/* Весь скролл */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  $color: #bfd2ff;

  background-color: transparentize($color, .5);
  border-radius: 10px;

  &:hover {
    background-color: $color;
  }
}
