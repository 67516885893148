
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        











































































































































@import "~@/scss/competition-banner";

::v-deep {
    .dropdown .dropdown-menu {
        right: -70px !important;
    }
}

.progressBar {
    position: relative;
    width: 120px;
    height: 24px;

    padding: 4px;
    background-color: black;
    border-radius: 12px;

    &:hover .progressBar-amountPercent {
        display: block;
    }

    &-icon-left {
        position: absolute;
        z-index: 3;
        top: -26%;
        left: -18%;

        height: 38px;
        width: 38px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 999px;
        background-color: black;

        img {
          margin-top: 1px;
          height: 24px;
          width: 24px;
        }
    }

    &-bg {
        $dark-gray: #333333;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;

        height: 67%;
        width: 93%;
        border-radius: 8px;
        background-color: $dark-gray;
    }

    &-line {
        position: relative;
        z-index: 2;
        height: 100%;
        border-radius: 8px;
        background: $main-color;

        .progressBar-amountPercent {
            display: none;

            position: absolute;
            top: -113%;
            right: -20%;
            padding: 2px 5px;
            z-index: 11;

            font-weight: 600;
            font-size: 10px;
            line-height: 1.4;
            color: #FFFFFF;

            background-color: black;
            border-radius: 8px;
        }
    }
}

.default-text-style {
    color: $light-gray_;
    font-size: 14px;
    line-height: 1.5;
    white-space: pre;
}

.count {
    font-weight: 600;
    font-size: 24px;
    color: $dark-brown;
}

.progressBar-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 0.5rem;
}

.competitionInfo {
    $self: &;
    $border-radius: 10px;
    $inner-indent: 10;

    ::v-deep {
        .dropdown-content {
            padding: 0;
            border-radius: $border-radius;
        }

        .dropdown-menu {
            position: absolute;
            left: -50px;

            padding-top: #{ $inner-indent }px;
            border-radius: $border-radius;

            &::before {
                content: "";

                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;

                width: 100%;
                height: calc(100% - #{ $inner-indent }px);

                border-radius: $border-radius;
                box-shadow: 0 4px 4px 0 #25062C40;
            }
        }
    }

    &-wrapper {
        position: relative;

        overflow: hidden;
        border-radius: $border-radius;
        background-color: black;

        #{ $self }-body {
            position: relative;
            width: 350px;

            padding: 32px;
            border-radius: $border-radius;

            &::before {
                content: "";
                position: absolute;
                right: 0;
                bottom: 0;

                width: 110px;
                height: 202px;
                background-image: url("~@/assets/Competition/Raffle/dropdown-bg.png");
                background-size: cover;
                background-repeat: no-repeat;
            }

            &_line {
                display: block;

                width: 100%;
                height: 1px;
                margin: 24px 0;
                background-color: $dark-brown;
            }

            &_description {
                margin-bottom: 24px;

                color: $light-gray;
                font-size: 14px;
                line-height: 1.43;
            }

            &_about-competition {
                font-size: 14px;
                line-height: 1.43;
                color: $main-color;
            }
        }
    }
}

.dropdown-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 2rem;
}
