
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        






















































































































































































































































































































































































































































































































































































































::v-deep {
  .navbar.is-fixed-top {
    z-index: 35;
  }
}

.navbar {
  $self: &;

  ::v-deep {
    #{ $self }-menu.is-active {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: stretch;
      }
    }

    #{ $self }-start {
      .columns {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}

.brand {
  display: flex;
  justify-content: space-between;

  width: 100%;

  ::v-deep {
    .aside-menu-button {
      // noinspection SassScssUnresolvedMixin
      @include desktop {
        display: none;
      }
    }
  }
}

.burger {
  display: flex;
  align-items: center;

  padding: 0 1.5rem 0 1rem;
  cursor: pointer;

  //noinspection SassScssUnresolvedMixin
  @include desktop {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .user-login {
    display: none;
  }
}
