
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        







































































































.card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: $white-gray;
    height: 330px;
    max-width: 400px;
}
.card-image img {
    display: block;
    margin: 0 auto;
    width: 165px;
    height: 141px;
    padding-top: 20px;
}
::v-deep {
    .card-content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      text-align: center;
    }
}
.card-content button {
    padding: 1.25rem 1em;
}

::v-deep {
    .modal-card {
        width: calc(300px + 30vw);
        max-width: 650px;
    }
}
