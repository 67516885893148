.chat-cover {
    display: flex;
    max-height: 46vh;
    overflow-y: auto;
    border-radius: 5px;
    padding: 25px 20px;
    margin-bottom: 20px;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px solid #EFEFEF;
}

.chat-item {
    display: flex;
    max-width: 100%;
    margin-right: auto;
    margin-bottom: 1rem;
    flex-direction: column;

    &:last-of-type {
        margin-bottom: 0;
    }

    &--user {
        margin-left: auto;
        margin-right: 0;
        align-items: flex-end;

        .chat-item__body {
            background-color: #9E8CC8;
            color: #fff;

            &::before {
                left: auto;
                right: 15px;
                background-color: #9E8CC8;
            }
        }

        .loader {
            margin-left: auto;
        }
    }

    &__body {
        position: relative;
        background-color: #ECECEE;
        font-size: 0.875em;
        border-radius: 5px;
        padding: 15px;
        //&::before {
        //    content: '';
        //    display: block;
        //    position: absolute;
        //    background-color: #ECECEE;
        //    bottom: -4px;
        //    left: 15px;
        //    width: 10px;
        //    height: 10px;
        //    transform: rotate(45deg);
        //}
    }

    &__text {
        background-color: transparent;
        color: inherit;
        font-size: inherit;
        overflow-x: auto;
        font-family: inherit;
        white-space: pre-line;
        word-wrap: normal;
        word-break: break-word;
    }

    &__img {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #777;
        margin-top: 15px;
        margin-left: 20px;
        transform: translateX(-50%);
    }
    
    //noinspection SassScssUnresolvedMixin
    @include tablet {
        max-width: 80%;
    }
}
