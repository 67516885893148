
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        
























.virtual-loading-wrapper {
  margin: 0 auto;
  position: absolute;
  left: 50%;
  .virtual-loading {
    margin-bottom: 10px;
    position: sticky;
    display: flex;
    flex-direction: column;
    top: 47%;
    button {
      background: none;
    }
  }
}
