
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        






























































































































































































































































::v-deep {
  .animation-content {
    width: 100%;
    max-width: 1000px !important;

    .modal-card {
      width: 95%;
      margin: 0 auto;
      max-width: none;
      min-height: 250px;

      .modal-card-foot {
        padding: 10px;
      }
    }
  }

  .table {
    $border-radius: 5px;

    border-spacing: 0 18px;

    tbody tr td {
      padding: 12px 14px;
      background-color: $white-gray;
      font-size: 14px;

      &:first-child {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }

      &:last-child {
        vertical-align: bottom;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }

      @media screen and (max-width: 769px){
        &::before {
          display: none;
        }

        &:last-child, &:first-child  {
          border-radius: 0;
        }
      }
    }

    thead {
      display: none;
    }

    h2, .out-transaction-type {
      text-wrap: balance;
    }

    h2 {
      font-weight: 600;
    }
  }
}
