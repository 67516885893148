
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        























































::v-deep {
    @media screen and (max-width: 1023px) {
        .navbar {
            z-index: 40 !important;
        }
    }
}

.full {
    display: flex;
    margin-right: 8px;
    margin-bottom: 8px;
}
