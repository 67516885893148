
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        
























































































































































@import "src/scss/theme-default";

.input {
    text-overflow: ellipsis;

    &:focus {
        box-shadow: initial;
        border-color: #e9e9e9;
    }
}

.button {
    &.is-disabled {
        border: none !important;
        //noinspection SassScssUnresolvedVariable
        background-color: $background;
    }

    .icon {
        color: rgba(0, 0, 0, 0.4);
    }
}
