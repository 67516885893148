
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        




















































































































































































::v-deep {
    .table thead {
        display: none;
    }
}
.card {
    $self: &;

    background-color: $white-gray;

    .is-fullwidth {
        width: 100%;
    }

    ::v-deep {
        #{ $self }-header {
            position: sticky;
            top: 0;
            z-index: 1;

            padding-bottom: $size-7;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            background-color: $white-gray;

            &-title {
                padding: $size-5 $size-5 0;
                font-weight: $weight-semibold;
            }
        }

        #{ $self }-content {
            overflow: auto;
            max-height: 350px;

            padding: 0 $size-5 $size-5;

            span {
                font-size: 0.85rem;
            }
        }
    }
}
