@import "theme-default";

.st-table {
    //max-height: calc(100vh - (16px * 2 + #{ $navbar-height }));

    .th-wrap {
        color: #CDCDCD;
        font-size: 0.875em;
    }

    .is-loading {
        &:after {
            z-index: 2;
        }
    }

    tr {
        th, td {
            vertical-align: middle;
            border-bottom: none !important;
            border-top: 1px solid #EDEDED !important;
        }
    }

    thead {
        background-color: #fff;
        position: sticky;
        top: $navbar-height;
        z-index: 29;
        @include touch {
            top: 0; // fix touch screen header
        }

        @include mobile {
            top: 0
        }

        tr {
            &:first-child {
                th {
                    border-top: none !important;
                }
            }

            &:not(:first-child) {
                th {
                    z-index: 2;
                    background-color: white;
                    position: sticky !important;
                    //top: calc(#{ $navbar-height });
                    border-bottom: 1px solid #EDEDED !important;
                }
            }

            th {
                vertical-align: middle;
            }
        }
    }

    tbody {
        background-color: transparent;

        tr {
            &:hover {
                background-color: whitesmoke;
            }

            &:first-child {
                td {
                    border-top: none !important;
                }
            }

            td {
                position: relative;

                //&:before {
                //    display: block;
                //    content: '';
                //    background: #EDEDED;
                //    position: absolute;
                //    width: 1px;
                //    height: 75%;
                //    right: 0;
                //    top: 50%;
                //    transform: translateY(-50%);
                //}
            }
        }
    }

    tfoot {
        tr {
            td {
                bottom: 0;
                z-index: 2;
                background-color: white;
                position: sticky !important;
            }
        }
    }

    .column {
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        position: relative;

        &:nth-child(1) {
            box-shadow: none;
        }

        &:nth-child(2) {
            z-index: 2;
        }

        &:nth-child(3) {
            z-index: 3;
        }

        &:nth-child(4) {
            z-index: 4;
        }

        &:nth-child(5) {
            z-index: 5;

            td:last-of-type {
                &::before {
                    display: none;
                }
            }
        }
    }
}

.icon-table-head {
    max-width: 38px;
}

.small-col {
    max-width: 50px;
}

.table-col {
    flex-grow: 1;
    display: flex;


    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.9em;
        flex-grow: 1;

        &--half {
            flex-basis: 50%;
        }
    }
}
