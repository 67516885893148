
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        








































.required-field {
    &__label {
        position: relative;
        display: inline-flex !important;
        align-items: center;
        column-gap: 0.4rem;

        &--required:after {
            content: "";
            position: absolute;
            top: 5px;
            right: -7px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: $danger;
        }
    }
}
