
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        






















































































span {
  font-size: 0.875em;
}

.checkbox-tag {
    position: absolute;
    top: -50%;
    right: 0;
    transform: translate(15%, 80%);
    padding: 0 8px;
    font-size: 10px;
}

.checkbox-wrapper {
    padding: 8px 10px;
    background: $primary-light;
    border-radius: 6px;
    position: relative;
}

.switch.is-flex-direction-row-reverse {
    margin-right: 0;
    column-gap: .5em;
}
