.card:not(:last-child) {
    margin-bottom: $default-padding;
}

.card {
    border-radius: 10px;


    .card-header {
        font-size: 18px;
        line-height: 21px;
    }

    &.has-table {
        .card-content {
            padding: 0;
        }
    }

    .card-content {
        hr {
            margin-left: $card-content-padding * -1;
            margin-right: $card-content-padding * -1;
        }
    }

    .has-widget-icon {
        border-right: 1px solid $white-bis;
    }

    .is-widget-icon {
        background-color: $white-bis;
        border-radius: $radius-rounded;

        .icon {
            width: 5rem;
            height: 5rem;
        }
    }
}

.card-content-wrapper {
    // position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
