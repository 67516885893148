
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        
















































































.banner {
    background-color: #16052f;
    min-width: 50%;
    width: 50%;
    background-image: url("~@/assets/Dashboard/competition/Mountain.png");
    border-top-left-radius: $radius-large;
    border-bottom-left-radius: $radius-large;
    background-position: left;
    overflow: hidden;
    background-size: cover;

    &-date {
        color: $primary-invert;
        font-size: $size-6;
        padding: $size-7 0;
    }

    &-title {
        font-size: $size-3;
        font-weight: $weight-bold;
        padding: 0 $size-6;
        color: white;
    }

    &-description {
        color: $primary-invert;
        font-size: 13px;
        padding: $size-7 $size-5;
        text-transform: uppercase;
    }

    .competition-text-style {
        color: $competition-color;
    }

    .button {
        padding: 19px 16px;
        border-radius: 10px;
    }

    ::v-deep {
        .button {
            font-size: $size-7;
        }
    }

    @media screen and (max-width: 825px) {
        display: none !important;
    }
}
