.chart {
    min-height: 500px;
    //noinspection SassScssUnresolvedVariable
    border-radius: $radius-large;
}

.chart-tooltip {
    $self: &;

    &__list {
        margin-top: 10px;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.opacity {
            color: $light;

            #{ $self }__item-label,
            #{ $self }__item-value {
                color: $light !important;
            }
        }

        &-label {
            color: transparentize(#000, 0.1);
            padding-right: 40px;
        }

        &-value {
            margin-left: auto;
            padding-left: 1em;
            color: black;
            text-align: right;
        }
    }
}
