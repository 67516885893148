
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

























































































































.card-footer-item {
    padding: 0;

    &::v-deep > * {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 0.75rem;
        justify-content: center;
    }
}
