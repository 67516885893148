
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        















































































































































































































































::v-deep {
    footer {
        padding: 0;
    }

    .modal-card-body {
        padding: 0 12px;
    }

    .modal-card {
        width: 650px;
        border: none;

        &-head {
            border-bottom: 1px solid #ECECEC;
        }
    }

    .v-popover, .trigger {
        width: 100%;
    }
}

.shouldShrink {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
    white-space: nowrap;
}

.block {
    $self: &;

    &-box {
        max-width: 280px;

        //noinspection CssInvalidPseudoSelector
        &::v-deep #{ $self }-title {
            display: block;
            margin: 0 0 10px;
            padding: 0 0 0 20px;
            font-size: 18px;
            line-height: 21px;
            color: #212121;
        }
    }
}

.order {
    &-btn {
        width: 100%;
        height: 47px;
        font-weight: 600;
        line-height: 16px;
    }
}
