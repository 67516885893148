
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        





































































































$radius: 70;

.progress-bar {
  z-index: 100;
  position: fixed;
  right: 30px;
  bottom: 25px;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .circle{
    width: $radius + 18 + px;
    height: $radius + 18 + px;
    border-bottom: 4px dashed #487dff;
    border-radius:50%;
    position:absolute;
    overflow:hidden;
    z-index: 10;
    animation: circle 2s linear infinite;
  }
  .button {
    width: $radius + px;
    height: $radius + px;

    .outputValue {
      font-size: 18px;
    }
    img {
        font-size: $radius - 40 + px;
    }
  }
}
.media {
    min-width: 160px;
}

@keyframes circle{
  0%{
    transform:rotate(0deg)

  }
  100%{
    transform:rotate(360deg)
  }
}
