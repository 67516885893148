.is-clipped {
  body {
    overflow: hidden;
  }
}

.nowrap {
    flex-wrap: nowrap;
    white-space: nowrap;
}

.is-absolute {
  position: absolute;
}

.is-normal {
  font-weight: 400;
}

.is-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.borderless {
  border: none !important;
}