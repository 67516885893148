
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        


































































































::v-deep {
    .dropdown-content{
        width: 210px;
    }
}

.social-network-links, a{
    display: flex;
    position: relative;
    align-items: center;
}

.button{
    border: none;
    padding: 0;

    img{
        width: 26px;
        height: 26px;
    }
}

.link-wrapper{
    display:flex;
    align-items: center;
    justify-content: center;
    gap:10px;
    color: #212121;
}
