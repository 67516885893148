@media screen and (min-width: 1920px) {
    @for $i from 0 through 12 {
        .column.is-#{$i}-real-fullhd {
            flex: none;
            width: 100% * $i / 12;
        }
    }
}

@media screen and (min-width: 550px) {
  .is-real-mobile-flex{
    display: flex;
  }
}

@media screen and (min-width: 550px) and (max-width: 769px) {
  @for $i from 0 through 12 {
    .column.is-#{$i}-real-mobile {
      flex: none;
      width: 100% * $i / 12;
    }
  }
}