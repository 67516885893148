
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        




















































































































































































































































































































$border-color: #dbdbdb;

::v-deep {
    .button:active,
    .button:focus {
        border-color: $light-gray !important;
    }

    .dropdown {
        &-content {
            min-width: 200px;
            max-width: 50%
        }
    }

    .w-70 {
        width: 70%;
    }
}

.country-number {
    $self: &;

    ::v-deep {
        .has-addons {
            #{ $self }-plus:not(.disabled) {
                border: 1px solid $border-color;
                background: white;
                border-right: none;
                border-top-left-radius: .25rem;
                border-bottom-left-radius: .25rem;
            }
            .input {
                border-left-width: 0;

                &:focus,
                &.is-danger {
                    border-color: $border-color;
                }
            }
        }
    }

    &:hover {
        ::v-deep {
            .has-addons {
                #{ $self }-plus {
                    border-color: $light;
                }
                .input:not(:disabled) {
                    border-color: $light;
                }
            }
        }
    }
}
