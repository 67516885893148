
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        















































































































































































$scroll-background-color: rgba(181, 181, 181, .3);
$header-text-color: #73879c;
$header-border-color: #e8e8e8;

.timepicker {
    width: 100%;

    ::v-deep {
        .dropdown {
            &-trigger {
                width: 100%;
            }

            &-content {
                font: 14px/1.5 "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei", sans-serif;
                color: $header-text-color;
                width: 224px;
                height: 224px;
                box-sizing: border-box;
                overflow: hidden;

                .time {
                    &-header {
                        padding: 6px 0;
                        border-bottom: 1px solid $header-border-color;
                    }

                    &-columns {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                    }

                    &-column {
                        position: relative;
                        overflow: hidden;
                        flex: 1;
                        border-left: 1px solid #e8e8e8;
                        text-align: center;
                        height: 100%;

                        &:first-child {
                            border-left: 0;
                        }
                    }

                    &-wrapper {
                        height: 85%;
                        overflow-x: hidden;
                        overflow-y: auto;
                        scrollbar-width: thin;

                        &::-webkit-scrollbar {
                            width: 0.5rem;
                            height: 100%;
                        }
                        &:hover {
                            &::-webkit-scrollbar-thumb {
                                background-color: $scroll-background-color;
                                border-radius: 9999px;
                                border: 1px solid $white;
                            }
                        }
                    }

                    &-list {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                    }

                    &-item {
                        cursor: pointer;
                        font-size: 12px;
                        height: 32px;
                        line-height: 32px;

                        .b-radio {
                            font-size: 0.9rem;
                            border: none;
                            border-radius: 0;
                            background-color: transparent;
                            padding-right: 0.5rem;

                            &.is-selected {
                                color: $primary;
                            }
                        }
                    }
                }
            }
        }
    }
}
