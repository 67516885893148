@import "theme-default";

input {
    &:disabled {
        background-color: #fff;
    }
}

.control {
    input {
        &::placeholder {
            font-weight: $placeholder-font-weight;
            color: $placeholder-color;
        }

        &::-webkit-input-placeholder {
            font-weight: $placeholder-font-weight;
            color: $placeholder-color;
        }

        &::-ms-input-placeholder {
            font-weight: $placeholder-font-weight;
            color: $placeholder-color;
        }
    }
}
