
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        























































































































































::v-deep {
  .collapse-trigger {
    display: block !important;
    margin: .4rem .6rem;
  }
}

.trigger {
  &-wrapper {
    display: flex;
    align-items: center;
    position: relative;

    &:hover {
      a.external-item::before {
        background-color: rgba(255, 186, 65, 0.2);
      }
    }
  }

  &-icon {
    width: 10%;
    padding: 0 .6rem 0 0;
  }
}
