
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        














































































































































































































::v-deep {
    .animation-content {
        width: 100%;
        max-width: 500px !important;

        .modal-card {
            width: 100%;
            max-width: none;
            border: none;
        }
    }

    .preview {
        border: 2px solid #FFBA41;
    }

    .vue-advanced-cropper {
        &__foreground {
            opacity: 0.32;
        }

        &__background {
            background: transparent;
        }
    }

    .vue-bounding-box div:nth-child(2) {
        display: none;
    }

    .upload {
        .upload-draggable {
            padding: 0;
            border: 0;

            .section {
                padding: 1rem 3rem;
            }

            path, circle {
                transition: all .1s ease-in-out;
            }

            &:hover.is-primary,
            &.is-hovered.is-primary {
                background: transparent;
            }

            &:hover path,
            &.is-hovered path {
                stroke: $sky-blue;
            }

            &:hover circle,
            &.is-hovered circle {
                fill: #E7EBFD;
            }
        }
    }
}

.cropper,
.cropper-wrapper {
    max-width: 280px;
    width: 100%;
    height: 330px;
}

.cropper-wrapper {
    position: relative;
    background: white;
    overflow: hidden;
}

.cropper-buttons {
    position: absolute;
    bottom: 6px;
    right: 6px;

    button {
        background-color: rgb(0 0 0 / 50%) !important;
        padding: 0.4rem;
        height: 1.8rem;

        &:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            img {
                transform: scaleX(-1);
            }
        }

        &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

.image-background {
    position: absolute;
    left: -10px;
    top: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);

    background-size: cover;
    background-position: 50%;
    filter: blur(5px);
}
