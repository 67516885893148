
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        





































































































































































































































































































































































































::v-deep {
  .animation-content {
    width: 100%;
    max-width: 600px !important;

    .modal-card {
      width: 95% !important;
      margin: 0 auto;
      max-width: none;
    }
  }

  .control-label {
    padding-left: 5px !important;
  }

  .is-danger {
    .input, .input-wrapper {
      border-color: red;
    }
  }

  .modal-card {
    &, .modal-card-body {
      overflow: visible;
    }
    .modal-card-head, .modal-card-foot {
      border-radius: 10px;
    }
    .modal-card-foot {
      padding: 10px 30px 25px 30px;
    }
  }
}

//noinspection SassScssUnresolvedMixin
@include mobile {
  .type-buttons {
    ::v-deep {
      .field-body {
        .field.has-addons {
          flex-direction: column;

          .control {
            &:first-child {
              .button {
                border-top-right-radius: 5px !important;
                border-bottom-left-radius: 0 !important;
              }
            }

            &:last-child {
              .button {
                border-top-right-radius: 0 !important;
                border-bottom-left-radius: 5px !important;
              }
            }
          }
        }
      }
    }
  }
}

.column {
  padding: .45rem .75rem;
}
