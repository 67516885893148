
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        






















































































































































































.scrollbar {
    $self: &;
    $color: #bfd2ff;
    $width-scroll: 8px;
    $space-scroll: 2px;
    $margin-scroll: 6px;

    &-container {
        position: fixed;
        right: $space-scroll;
        bottom: $space-scroll;
        z-index: 100;
        border: 3px solid transparent;
        border-radius: 4px;
        transition: opacity 250ms ease-out;

        &:hover,
        &.is-focus {
            opacity: 1 !important;

            #{ $self }-inner {
                background-color: $color;
            }
        }

        &.is-vertical {
            width: $width-scroll;
            top: $space-scroll;
            padding-right: $margin-scroll;

            #{$self}-inner {
                width: $width-scroll;
            }
        }

        &.is-horizontal {
            height: $width-scroll;
            left: $space-scroll;
            padding-bottom: $margin-scroll;

            #{$self}-inner {
                height: $width-scroll;
            }
        }
    }

    &-inner {
        position: relative;
        display: block;
        width: 0;
        height: 0;
        cursor: pointer;
        border-radius: inherit;
        background-color: $color;
        transition: opacity 250ms linear;
    }
}
