.select {
    select {
        &:active,
        &:focus {
            box-shadow: none;
        }
    }

    &:not(.is-multiple):not(.is-loading)::after {
        display: none;
    }
}

