
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        






























































































































































































































































































$width-description: 200px;
$black: #212121;
$color-light: transparentize($light, 0.7);

.flip-list-move {
    transition: transform .5s;
}

.description {
    max-width: $width-description;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.description, .worked-year {
    height: fit-content;
    margin-top: 6px;
    padding: 0 6px;
    font-size: 13px;
    color: #212121;
    background-color: $color-light;
    border-radius: $radius-rounded;
    border-top-left-radius: 0;
}

.icon-wrapper {
    background-color: $black;
    width: 20px;
    height: 20px;
    border-radius: $radius-rounded;
    padding: 5px;
    display: flex;

    ::v-deep {
        .icon {
            width: 100%;
            font-size: 0.6rem;
        }
    }
}

.title-worked-year {
    font-size: 14px;
    color: $black;
}

.dropdown {
    ::v-deep {
        .dropdown-content {
            min-width: 270px;
            padding: $size-5;
            margin-top: 1rem;

            .image-wrapper {
                $size: 40px;
                $borderSize: 40px;
                opacity: 1;
                position: relative;
                border-color: $info;
                height: fit-content;

                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: $borderSize;
                    height: $borderSize;
                    border: 2px solid $info;
                    border-radius: $radius-rounded;
                }

                img {
                    display: flex;
                    min-width: $size;
                    width: $size;
                    height: $size;
                    border-radius: $radius-rounded;
                }
            }

            .manager-wrapper {
                margin-left: $size-7;
            }

            .navbar-divider {
                height: 1px;
                background-color: $light;
                width: 100%;
                margin: $size-7 0;
            }
        }

        .dropdown-trigger {
            .manager-wrapper {
                .title {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    max-width: $width-description;
                }
            }
        }

        .dropdown-item {
            padding: calc(#{ $size-6 } / 2) 0;

            &:nth-child(3) {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}

.manager {
    $self: &;
    $size: 31px;
    $bigSize: 35px;

    position: relative;

    & span {
        display: flex;
        align-items: center;
        //column-gap: .8rem;
    }

    &, &-wrapper {
        display: flex;

        & .title {
            //text-overflow: ellipsis;
            //overflow: hidden;
            white-space: nowrap;
            //max-width: $width-description;
        }
    }

    .selectable {
        display: inline-block;
        opacity: .7;
        cursor: pointer;

        &:first-child {
            &:hover {
                //margin-right: .3rem !important;
                //transition: margin 0.5s, width 0.5s, height 0.5s;

                //img {
                //    width: $bigSize;
                //    height: $bigSize;
                //}
            }
        }

        &:last-child {
            $borderSize: 43px;
            opacity: 1;
            position: relative;
            border-color: $info;
            pointer-events: none;
                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: $borderSize;
                    height: $borderSize;
                    border: 2px solid $info;
                    border-radius: 50%;
                }
        }
    }

    .image-wrapper {
        height: fit-content;
        border-radius: 50%;
        position:relative;

        img {
            border: 1px solid $light;
        }

        &:first-child {
            width: calc(#{$size} * 1.6);
            margin-right: -.8rem;

            &:hover {
                padding-left: 0;
                transition: padding 0.5s, width 0.5s, height 0.5s;

                img {
                    width: $bigSize;
                    height: $bigSize;
                }
            }
        }

        &:last-child {
            display: flex;
            justify-content: center;
            margin-right: .8rem;

            img {
                width: $bigSize;
                height: $bigSize;
            }
        }

        .default-image {
            display: flex;
            justify-content: center;
            align-items: center;
            width: $size;
            height: $size;
            background: #FFBA41;
            border-radius: 50%;
            font-size: 15px;
            color: white;
            user-select: none;
        }
    }

    .paddingless:first-child {
        padding-left: .8rem;
    }

    ::v-deep {
        .dropdown {
            $self: &;

            //noinspection SassScssUnresolvedVariable
            #{ $self }-menu {
                padding: 0;
                width: 100%;
                margin: 16px 0;

                //noinspection SassScssUnresolvedVariable
                #{ $self }-content {
                    width: 100%;
                }
            }
        }
    }

    img {
        //noinspection SassScssUnresolvedMixin
        @include until-widescreen {
            //noinspection SassScssUnresolvedVariable
            width: $size;
            //noinspection SassScssUnresolvedVariable
            height: $size;
        }

        //noinspection SassScssUnresolvedMixin
        @include widescreen {
            //noinspection SassScssUnresolvedVariable
            width: $size;
            //noinspection SassScssUnresolvedVariable
            height: $size;
        }

        display: block;
        border-radius: 50%;
    }
}
