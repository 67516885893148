html {
    &.has-aside-left {
        &.has-aside-expanded {
            nav.navbar, body.has-navbar-fixed-top {
                padding-left: $aside-width-widescreen;
            }
        }

        nav.navbar, body {
            @include transition(padding-left);
        }
    }
}

@include touch {
    html {
        &.has-aside-left {
            &.has-aside-expanded {
                nav.navbar, body.has-navbar-fixed-top {
                    padding-left: 0 !important;
                }
            }

            nav.navbar, body {
                @include transition(padding-left);
            }
        }
    }
}

nav.navbar {
    @include desktop {
        padding-right: 1.2rem;
    }
}

body.has-navbar-fixed-top {
  padding-left: $aside-width-widescreen !important;
}

body.has-navbar-fixed-top.is-mini {
  padding-left: $aside-width-until-widescreen !important;
}