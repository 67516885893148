.button, nav.navbar, .card-header-title {
    .icon {
        height: 100%;
    }
}

@include touch {
    .navbar-item:not(.has-dropdown), .navbar-link {
        display: flex;
    }
}

