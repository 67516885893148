
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        
























































































































.notification {
    &-button {
        width: 26px;
        height: 26px;
        border-radius: 9999px;
        cursor: pointer;
      background-color: $info-light;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    &-badge {
        position: absolute;
        background-color: $info;
        color: white;
        font-size: 10px;
        bottom: -6px;
        right: -6px;
        min-width: 14px;
        height: 14px;
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 0;
        padding: 2px;
    }
}

::v-deep {
    .dropdown-content {
        max-height: 280px;
        overflow-y: auto;
    }
}
