
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        





































































































































































































































































::v-deep {
    * {
        text-align: left !important;
    }

    .modal-card-foot {
        padding: 20px 30px 30px;
    }

    .pointer-events-none {
        pointer-events: none;
    }

    .animation-content {
        width: 100%;
        max-width: 700px !important;

        .modal-card {
            width: 100%;
            max-width: none;
        }
    }

    .card-profile {
        &_body {
            &-wrapper {
                flex-wrap: wrap;
            }

            &-team {
                display: flex;
                align-items: center;

                width: 100%;
                margin: .5rem 0;
                padding: 16px 20px;
                background: $info;
                border-radius: 8px;

                &_title, &_item {
                    color: #FFFFFF;
                }

                &_title {
                    font-size: 0.813rem;
                }

                &_item {
                    font-weight: 600;
                    font-size: .850rem;
                    white-space: normal;
                    letter-spacing: .8px;
                }
            }

            &-item {
                width: 100%;
                margin-top: .9rem;

                &_title {
                    font-size: 0.875rem;
                    color: #8D8A8A;
                }

                &_content {
                    font-weight: 600;
                    font-size: .813rem;
                    white-space: normal;
                    overflow: hidden;
                }
            }

            &_item-info {
                display: flex;
                align-items: center;
                justify-content: center;

                margin: .5rem .625rem 0 0;
                padding: .438rem .75rem ;

                font-size: .87rem;
                color: #FFFFFF;
                background: $info;
                border-radius: 4px;
            }

            @media screen and (min-width: 600px) {
                &-wrapper {
                    display: flex;
                    justify-content: space-between;
                }

                &-team, &-item {
                    width: 48.5%;
                }
            }
        }
    }

    .card-title {
        margin-top: 1rem;

        font-size: 1.25rem;
        font-weight: 600;
    }
}

.card-profile {
    &_header {
        $border-color: #E1E1E1;

        display: flex;
        align-items: center;

        &-img {
            $size: 6.5rem;

            width: $size;
            height: $size;

            margin-right: 1.25rem;
            border-radius: 50%;
            border: 1px solid  $border-color;
        }

        &-name {
            font-size: 1.063rem;
            font-weight: 600;
            color: #212121;
        }
    }
}

.card-title {
    margin-top: 1rem;

    font-size: 1.25rem;
    font-weight: 600;
}
