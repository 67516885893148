
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        




























































//.output {
//    display: flex;
//    align-items: center;
//    .name {
//        overflow: hidden;
//        text-overflow: ellipsis;
//        max-width: 240px;
//        display: inline-block;
//    }
//}
.is-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.is-centered {
  vertical-align: middle;
}
