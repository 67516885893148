
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        














































































































































































































$border-color: #dbdbdb;
$border-color-invert: #b5b5b5;

.border {
    border: 1px solid $border-color;
    border-radius: 4px;

    &:hover:not(.invalid) {
        border-color: $border-color-invert;

        .l-select {
            border-color: $border-color-invert;
        }
    }
}

.invalid {
  border-color: $danger;
}

::v-deep {
    .columns {
        @media screen and (min-width: 465px) {
            display: flex;
        }
    }

    .column {
        min-width: 90px;
    }

    .input {
        min-width: 90px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .no-border input, .vs__dropdown-toggle {
        border: none;
    }

    .control {
        .icon {
           display: none;
        }

        &:hover .vs__dropdown-toggle {
            border: none;
        }
    }

    .l-select {
        border-radius: 0 !important;
        border-right: 1px solid $border-color;
        border-left: 1px solid $border-color;

        &:hover {
            border-color: $border-color-invert;
        }

        @media screen and (max-width: 465px) {
            border: none;
            border-top: 1px solid $border-color;
            border-bottom: 1px solid $border-color;
        }
    }

    .invalid {
        .l-select {
            border-color: $danger;
        }
    }
}
