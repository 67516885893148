
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        















































































































































































































































































































































.pagination {
    padding-top: $size-4;
    padding-bottom: $size-4;

    &:last-of-type {
        padding-top: 0;
    }
}

::v-deep {
    .pagination-input {
        order: 4 !important;
    }

    td[rowspan] .group-wrapper {
      position: sticky;
      top: 220px;
      bottom: 80px;
    }

    .common-table {
        tfoot {
            position: sticky;
            bottom: 0;
            z-index: 2;
            background: #F5F8FA;
            td {
                border: none;
            }
            .total-number {
                font-size: 14px;
            }
        }

        .table-wrapper {
            overflow: inherit !important;

            th.is-sticky,
            td.is-sticky {
                @include transition(left);

                //noinspection SassScssUnresolvedMixin
                @include widescreen {
                    left: calc(#{ $aside-width-widescreen } + 1rem);
                }
            }

            th {
              .th-wrap.is-relative {
                position: static !important;
              }
              .icon.sort-icon {
                right: 0 !important;
                left: auto !important;
              }
            }

            th.is-sticky {
                background-color: $base-color-light;
                box-shadow: -24px 0px 0px $base-color-light;
            }

            td.is-sticky {
                background-color: $white;
                box-shadow: -24px 0px 0px $base-color-light;
            }
        }
    }
}
