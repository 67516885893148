
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        















































































































































body:has(.is-mini) .tag-is-new {
  display: none;
}

a {
  $size-icon: 18px;
  $collapse-menu-color: #969696;

  padding: .7rem .75rem;
  //noinspection SassScssResolvedByNameOnly
  fill: $menu-item-color;
  width: 100%;
  display: flex;
  align-items: center;

  .menu-item {
    $size-point: 8px;
    position: relative;

    &-dash {
      flex: 0 0 6px;
      height: 6px;

      margin-right: 8px;
      border-radius: 50%;
      opacity: 0;
      //noinspection SassScssResolvedByNameOnly
      background-color: $collapse-menu-color !important;
    }

    &-point {
      position: absolute;
      top: 4px;
      left: 28px;
      width: $size-point;
      height: $size-point;

      border-radius: 50%;
      //noinspection SassScssResolvedByNameOnly
      background-color: $primary !important;
    }

    &-icon {
      flex: 0 0 $size-icon;
      height: $size-icon;

      //noinspection SassScssUnresolvedMixin
      @include widescreen {
        margin-right: 10px;
      }
    }

    &-text {
      //noinspection SassScssResolvedByNameOnly
      color: $menu-item-color !important;
      opacity: 1;
      transition: opacity 250ms ease-in-out 50ms;
      line-height: 1.25;
      //white-space: nowrap;
    }
  }

  &.external-item::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    border-radius: 8px;
    pointer-events: none;
    z-index: -1;
  }

  &.inner-item {
    padding-left: 3rem;

    &, .menu-item-text {
      //noinspection SassScssResolvedByNameOnly
      color: $collapse-menu-color !important;
    }

    .badges {
      margin-left: 0.44rem;
    }
  }

  &.is-active, &:hover {
    &.inner-item {
      &, .menu-item-text  {
        //noinspection SassScssResolvedByNameOnly
        color: $menu-item-color !important;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        width: 4px;
        height: 85%;

        border-radius: 3px 0 0 3px;
        pointer-events: none;
      }

      .menu-item-dash, &::after {
        //noinspection SassScssResolvedByNameOnly
        background-color: $menu-item-color !important;
        opacity: 1;
      }
    }
  }

  &.is-active.external-item::before {
    //noinspection SassScssResolvedByNameOnly
    background-color: $menu-item-background-color;
  }
}
